import Vue from 'vue';
import Vuex from 'vuex';
// import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
  key: 'isi_presenter_viewer',
  storage: window.localStorage,
});
import EventService from '@/services/EventService';
import { get } from 'lodash';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    event: {},
    eventCustomCSS: '',
    eventColor: {},
    eventParams: {},
    userInfo: {},
    supportId: {},
    pollId: {},
    trackingId: {},
    isProtectedEvent: false,
    isNeedPurchase: false,
    verifyToken: {},
  },
  mutations: {
    RESET_STORE(state, eventId) {
      // state.event = {};
      delete state.userInfo[eventId];
      delete state.supportId[eventId];
      delete state.trackingId[eventId];
    },
    SET_EVENT_PARAMS(state, eventParams) {
      state.eventParams = eventParams;
    },
    SET_CUSTOM_CSS(state, eventCustomCSS) {
      state.eventCustomCSS = eventCustomCSS;
    },
    SET_EVENT_COLOR(state, eventColor) {
      state.eventColor = eventColor;
    },
    SET_EVENT(state, event) {
      state.event = {
        ...event,
        //youtubeURL: 'https://www.youtube.com/embed/z9q3EggY4Uc',
      };
    },
    SET_USERINFO(state, [userInfo, eventId]) {
      state.userInfo = {
        ...state.userInfo,
        [eventId]: userInfo,
      };
    },
    SET_POLLING_ID(state, [{ clientId, isSubmitted }, pollId, eventId]) {
      state.pollId = {
        ...state.pollId,
        [eventId]: {
          ...(state.pollId.eventId || {}),
          [pollId]: {
            clientId,
            isSubmitted,
          },
        },
      };
    },
    SET_SUPPORT_ID(state, [supportId, eventId]) {
      state.supportId = {
        ...state.supportId,
        [eventId]: supportId,
      };
    },
    SET_TRACKING_ID(state, [trackingId, eventId]) {
      state.trackingId = {
        ...state.trackingId,
        [eventId]: trackingId,
      };
    },
    SET_VERIFY_TOKEN(state, [verifyToken, email]) {
      state.verifyToken = {
        ...state.verifyToken,
        [email]: verifyToken,
      };
    },
    SET_PROTECTED_EVENT(state, isProtectedEvent) {
      state.isProtectedEvent = isProtectedEvent;
    },
    SET_NEED_PURCHASE_EVENT(state, isNeedPurchase) {
      state.isNeedPurchase = isNeedPurchase;
    },
  },
  actions: {
    async fetchEvent({ commit }, [eventId, password, isReset = true]) {
      try {
        if (isReset) {
          commit('SET_PROTECTED_EVENT', false);
          commit('SET_NEED_PURCHASE_EVENT', false);
        }
        const eventResult = await EventService.getEventInfo(eventId, password);
        commit('SET_EVENT_PARAMS', {
          eventId,
          password,
        });
        commit('SET_NEED_PURCHASE_EVENT', eventResult.message === 'This event need purchase first, please make payment before access to Event');
        commit('SET_EVENT', eventResult.data);
        commit('SET_CUSTOM_CSS', get(eventResult.data, 'customCSS', ''));
        commit('SET_EVENT_COLOR', {
          primaryColour: get(eventResult.data, 'primaryColour'),
          secondaryColor: get(eventResult.data, 'secondaryColor'),
        });
      } catch (error) {
        const errorApi = get(error, 'response.data.message');
        const errorCode = get(error, 'response.status');
        console.log({ error });
        if (errorApi === "The event does not exist or you don't have permission to view this event" && errorCode === 400) {
          commit('SET_PROTECTED_EVENT', true);
          commit('SET_CUSTOM_CSS', get(error, 'response.data.data.customCSS', ''));
          commit('SET_EVENT_COLOR', {
            primaryColour: get(error, 'response.data.data.primaryColour'),
            secondaryColor: get(error, 'response.data.data.secondaryColor'),
          });
        } else {
          commit('SET_EVENT', null);
          commit('SET_EVENT_PARAMS', {});
        }
        return errorApi;
      }
    },
    async fetchSupportConversation({ commit }, [supportId, eventId]) {
      try {
        commit('SET_SUPPORT_ID', [supportId, eventId]);
        // if (isEmpty(state.supportId)) {
        // const { firstName, lastName, id } = state.userInfo;
        // const resp = await EventService.getConversation({
        //   eventId,
        //   partner: id,
        //   name: `${lastName} ${firstName}`,
        // });
        // commit('SET_SUPPORT_ID', supportId);
        // }
      } catch (error) {
        commit('SET_SUPPORT_ID', [null, eventId]);
      }
    },
    setUserInfo({ commit }, [userInfo, eventId]) {
      commit('SET_USERINFO', [userInfo, eventId]);
    },
    setTracking({ commit }, eventId) {
      commit('SET_TRACKING_ID', [uuidv4(), eventId]);
    },
    setVerifyToken({ commit }, email) {
      commit('SET_VERIFY_TOKEN', [uuidv4(), email]);
    },
    setPolling({ commit }, [pollId, eventId]) {
      commit('SET_POLLING_ID', [
        {
          clientId: uuidv4(),
        },
        pollId,
        eventId,
      ]);
    },
    setPollingSubmitted({ commit }, [clientId, pollId, eventId]) {
      commit('SET_POLLING_ID', [
        {
          clientId,
          isSubmitted: true,
        },
        pollId,
        eventId,
      ]);
    },
    setConcludedEvent({ commit }, [eventId]) {
      commit('SET_USERINFO', [{}, eventId]);
    },

    setEventAccess({ commit }) {
      commit('SET_PROTECTED_EVENT', false);
    },
    resetStore({ commit }, eventId) {
      commit('RESET_STORE', eventId);
    },
  },
  plugins: [vuexLocal.plugin],
});
