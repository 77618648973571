import { apiClient, handleResp } from './utils';

export default {
  async createOrder(data) {
    return handleResp((await apiClient()).post('/order', data));
  },
  async getOrders() {
    return handleResp((await apiClient()).get('/order'));
  },
};
