<template>
  <div>
    <div ref="myscript" />
    <!-- <span class="text-red-300">{{ isProtectedEvent }}</span>
    <span class="text-red-300">{{ isCheckingPassword }}</span>
    <span class="text-red-300">{{ isPurchaseRequired }}</span> -->
    <div id="app" ref="appContent">
      <template v-if="some(policyRoute, item => item === $route.name)"> <router-view /> </template>
      <div v-else-if="loading_event || (empty(eventConclude) && !empty(event))" class="spinner-container">
        <div class="pulse-loader">
          <pulse-loader :loading="loading_event || empty(eventConclude)" />
        </div>
      </div>
      <div v-else-if="isPurchaseRequired" class="spinner-container">
        <div class="pulse-loader">
          <v-app>
            <v-card class="event-protect-container rounded-lg" style="padding: 0" elevation="10" :loading="paymentLoading">
              <template slot="progress">
                <v-progress-linear color="secondary" height="10" indeterminate />
              </template>
              <div class="px-4 pb-4">
                <v-card-title class="text-center"> You must pay ${{ event.price }} to access event. </v-card-title>
                <template v-if="!empty(paymentInfo)">
                  <CreditCardInput :stripe="stripe" :paymentInfo="paymentInfo" @loading="paymentLoading = $event" @onError="paymentLoading = false" @onSuccess="onPaymentSuccess" />
                  <v-alert type="success" class="mt-6" v-if="paymentLoading && waitingTime > 0">
                    <template v-slot:title> Payment successfully! </template>
                    Wating for entering event: <b>{{ waitingTime }}s</b>
                  </v-alert>
                </template>
                <v-btn v-else elevation="4" :color="colorFromEvent" rounded block @click="onMakeOrder" :loading="loadingMakeOrder"> Purchase </v-btn>
              </div>
            </v-card>
          </v-app>
        </div>
      </div>
      <div v-else-if="isProtectedEvent || isCheckingPassword" class="spinner-container">
        <div class="pulse-loader">
          <v-app>
            <v-card class="event-protect-container rounded-lg" elevation="10">
              <v-card-title class="text-center"> Event was protected by password </v-card-title>
              <v-card-subtitle> Please enter password before visiting event. </v-card-subtitle>
              <v-form ref="form" v-model="isValidProjectedForm" lazy-validation class="mb-7" @submit.prevent="submitPassword">
                <v-text-field :rules="passwordRules" v-model="password" label="Password" outlined required />
                <p class="error--text" v-if="!empty(errorText)">
                  {{ errorText }}
                </p>
                <v-btn :color="colorFromEvent" :disabled="!isValidProjectedForm" elevation="4" rounded block @click="submitPassword"> Submit </v-btn>
              </v-form>
            </v-card>
          </v-app>
        </div>
      </div>
      <v-app v-else>
        <Concluded v-if="eventConclude.status !== 'OPEN' && !empty(event)" :event="event" :type="eventConclude.status" />
        <router-view v-else :device="device" :orientation="orientation" :contentHeight="contentHeight" />
      </v-app>
    </div>
  </div>
</template>

<script>
import load from '@/plugins/dynamicLoadScript';
import ConcludedSubcription from '@/graphql/subscription/Concluded';
import ConcludedQuery from '@/graphql/query/Concluded';
import { mapState } from 'vuex';
import Concluded from '@/components/concluded';
import PulseLoader from 'vue-spinner/src/PulseLoader';
// import PollingService from '@/services/PollingService';
import { getAccessToken, getCurrentUser } from '@/aws/authentication';
import { empty } from '@/utils';
import CreditCardInput from '@/components/credit';
import OrderService from '@/services/OrderService';
import { some } from 'lodash';
export default {
  components: {
    Concluded,
    PulseLoader,
    CreditCardInput,
  },
  name: 'App',
  data: () => ({
    stripe: null,
    loading_event: true,
    isCheckingPassword: false,
    isValidProjectedForm: false,
    loadingMakeOrder: false,
    device: '',
    orientation: '',
    errorText: '',
    contentHeight: 0,
    password: null,
    passwordRules: [v => !!v || 'Password is required'],
    eventConclude: {},
    isValidToken: false,
    paymentInfo: {},
    paymentLoading: false,
    waitingTime: -1,
    policyRoute: ['Privacy', 'TermOfUse'],
  }),
  computed: {
    ...mapState({
      event: 'event',
      isProtectedEvent: 'isProtectedEvent',
      isNeedPurchase: 'isNeedPurchase',
      eventCustomCSS: 'eventCustomCSS',
      eventColor: 'eventColor',
      userInfo: 'userInfo',
    }),
    isPurchaseRequired() {
      return this.isNeedPurchase && !empty(this.userInfo) && this.isValidToken;
    },
    colorFromEvent() {
      const { primaryColour, secondaryColor } = this.eventColor;
      return primaryColour == '#ffffff' ? secondaryColor : primaryColour;
    },
    event_id() {
      return this.$route.params.event_id;
    },
  },
  apollo: {
    concludedData: {
      query: ConcludedQuery,
      variables() {
        return {
          eventId: this.event_id,
        };
      },
      skip: true,
      update: ({ getEventConclude }) => getEventConclude,
      error(error) {
        console.log('ConcludedQuery', { error });
      },
    },
    $subscribe: {
      concludeEvent: {
        query: ConcludedSubcription,
        variables() {
          return {
            id: this.event_id,
          };
        },
        // Disable the query
        skip: true,
        async result({
          data: {
            onUpdateEventConclude: { status },
          },
        }) {
          console.log('ConcludedSubcription', { status });
          if (status !== 'OPEN') {
            this.$store.dispatch('setConcludedEvent', [this.event_id]);
            this.eventConclude = {
              status,
            };
          } else {
            if (empty(this.userInfo[this.event_id]) || empty(await getCurrentUser())) {
              this.$router.push({
                name: 'Home',
                params: this.$route.params,
                replace: true,
              });
            }
            this.eventConclude = {
              status: 'OPEN',
            };
          }
        },
        error(error) {
          console.log('ConcludedSubcription', { error });
        },
      },
    },
  },
  watch: {
    isNeedPurchase: {
      async handler(value) {
        const notRedirect = ['ActiveAccount', 'ForgotPassword'];
        const isRedirect = !some(notRedirect, item => item === this.$route.name) && !some(this.policyRoute, item => item === this.$route.name);
        if (value && empty(await getAccessToken()) && isRedirect) {
          this.$router.push({
            name: 'Home',
            params: this.$route.params,
            replace: true,
          });
        }
      },
    },
    async waitingTime(value) {
      if (value === 0) {
        clearInterval(this.redirectInterval);
        await this.$store.dispatch('fetchEvent', [this.event_id, null, false]);
        this.isValidToken = false;
      }
    },
    '$route.params.event_id': {
      async handler(value) {
        this.loading_event = true;
        const { pwd } = this.$route.query;
        this.isValidToken = !empty(await getAccessToken());
        if (this.isValidToken) {
          await this.getUserInfo();
        }
        await this.$store.dispatch('fetchEvent', [value, pwd]);
        if (!this.isProtectedEvent) {
          this.doInitEvent();
        } else {
          this.setEventStyle();
        }
        this.$apollo.queries.concludedData.skip = false;
        this.$apollo.subscriptions.concludeEvent.skip = false;
        this.loading_event = false;
      },
    },
    event(value) {
      if (!empty(value?.font)) {
        const styleEle = document.createElement('style');
        styleEle.setAttribute('type', 'text/css');
        styleEle.innerHTML = `
        body {
            font-family: ${value.font} !important;
          }
          .v-application {
            font-family: ${value.font} !important;
          }
        `;
        document.head.appendChild(styleEle);
      }
    },
    async concludedData(value) {
      if (!empty(value)) {
        const { status } = value;
        if (status !== 'OPEN') {
          this.$store.dispatch('setConcludedEvent', [this.event_id]);
        }
        this.eventConclude = {
          status,
        };
      } else {
        this.eventConclude = {
          status: 'OPEN',
        };
      }
    },
  },
  mounted() {
    let script = document.createElement('script');

    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit');
    script.setAttribute('async', 'true');
    script.setAttribute('defer', 'true');
    script.setAttribute('data-transaction-amount', '14.90');
    this.$refs.myscript.appendChild(script);
    load('ResizeSensor', `/js/ResizeSensor.js`, async () => {
      new window.ResizeSensor(this.$refs.appContent, this.onResizeChange);
    });
    load('Stripe', `https://js.stripe.com/v3/`, () => {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
    });
  },
  methods: {
    empty,
    some,
    onPaymentSuccess() {
      this.waitingTime = 10;
      this.redirectInterval = setInterval(() => (this.waitingTime -= 1), 1000);
    },
    async onMakeOrder() {
      try {
        this.loadingMakeOrder = true;
        const resp = await OrderService.createOrder({
          productId: this.event_id,
        });
        console.log({ resp });
        this.paymentInfo = resp.data;
      } catch (error) {
        console.log({ error });
        this.$toast.error(error?.response?.data?.message, {
          position: 'bottom',
        });
      } finally {
        this.loadingMakeOrder = false;
      }
    },
    onResizeChange(size) {
      const { width, height } = size;
      this.contentHeight = height;
      this.orientation = this.getOrientation();
      if (width > 960) {
        this.device = 'desktop';
      } else {
        this.device = 'mobile';
      }
    },
    getOrientation() {
      const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
      if (orientation === undefined || orientation.startsWith('portrait')) {
        return 'portrait';
      }
      return 'landscape';
    },
    doInitEvent() {
      const { title } = this.event;
      document.title = title || '';

      this.setEventStyle();
      // let status, message;
      // if (isConclude) {
      //   status = 'CLOSED';
      //   message = this.event.endedMessage;
      // } else if (isPreEvent) {
      //   status = 'PRE';
      //   message = this.event.preEventMessage;
      // } else {
      //   status = 'OPEN';
      //   message = '';
      // }
      // this.$store.dispatch('setConcludedEvent', [
      //   {
      //     status,
      //     message,
      //   },
      //   id,
      // ]);
    },
    setEventStyle() {
      if (!empty(this.eventColor.primaryColour)) {
        this.$vuetify.theme.themes.light.primary = this.eventColor.primaryColour;
      }
      if (!empty(this.eventColor.secondaryColor)) {
        this.$vuetify.theme.themes.light.secondary = this.eventColor.secondaryColor;
      }
      if (!empty(this.eventCustomCSS)) {
        const styleEle = document.createElement('style');
        styleEle.setAttribute('type', 'text/css');
        styleEle.innerHTML = this.eventCustomCSS;
        document.head.appendChild(styleEle);
      }
    },
    async submitPassword() {
      try {
        this.isCheckingPassword = true;
        this.isValidProjectedForm = this.$refs.form.validate();
        if (this.isValidProjectedForm) {
          this.errorText = await this.$store.dispatch('fetchEvent', [this.event_id, this.password, false]);
          if (empty(this.errorText)) {
            await this.accessToEvent();
          }
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.isCheckingPassword = false;
      }
    },
    async accessToEvent() {
      if (!this.isPurchaseRequired && !this.isNeedPurchase && this.event?.isPaidEvent) {
        await this.getUserInfo();
      }
      await this.$store.dispatch('setEventAccess');
    },
    async getUserInfo() {
      if (this.event?.isPaidEvent) {
        const {
          attributes: { email, family_name, given_name, sub },
        } = await getCurrentUser();
        await this.$store.dispatch('setUserInfo', [
          {
            id: sub,
            email,
            firstName: family_name,
            lastName: given_name,
            eventId: this.event_id,
          },
          this.event_id,
        ]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.event-protect-container {
  width: 90%;
  max-width: 400px;
  max-height: 700px;
  padding: 20px;
  margin: auto;
}
</style>

<style lang="scss">
img {
  max-width: 100%;
}
</style>
