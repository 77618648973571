<template>
  <div class="fill-height">
    <StripeElementCard
      ref="stripeRef"
      :pk="publishableKey"
      hidePostalCode
      class="mt-4 mb-8"
      :style="{
        borderColor: 'red',
      }"
    />
    <v-btn :color="colorFromEvent" elevation="4" text :disabled="loading" rounded block @click="submitPayment">Confirm</v-btn>
  </div>
</template>
<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { wait, empty } from '@/utils';
import { mapState } from 'vuex';
import { getCurrentUser } from '@/aws/authentication';

export default {
  components: {
    StripeElementCard,
  },
  props: {
    stripe: {
      type: Object,
    },
    paymentInfo: {
      type: Object,
    },
  },

  computed: {
    ...mapState({
      event: 'event',
      eventColor: 'eventColor',
    }),
    event_id() {
      return this.$route.params.event_id;
    },
    colorFromEvent() {
      const { primaryColour, secondaryColor } = this.eventColor;
      return primaryColour == '#ffffff' ? secondaryColor : primaryColour;
    },
  },
  data: () => ({
    loading: false,
    publishableKey: process.env.VUE_APP_STRIPE_KEY,
    customer: {},
  }),
  methods: {
    empty,
    async submitPayment() {
      try {
        this.loading = true;
        this.$emit('loading', true);
        const {
          attributes: { email, name },
        } = await getCurrentUser();
        await wait(300);
        const cardElement = this.$refs.stripeRef.elements.getElement('card');
        const resp = await this.$refs.stripeRef.stripe.confirmCardPayment(this.paymentInfo.client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name,
            },
          },
          receipt_email: email,
        });
        console.log({ resp });
        if ('error' in resp) {
          this.$toast.error(resp.error.message, {
            position: 'bottom',
          });
        } else {
          this.$toast.success('Confirm order successful!', {
            position: 'bottom',
          });
          this.$emit('onSuccess');
        }
      } catch (error) {
        console.log({ error });
        this.$emit('onError');
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#stripe-element-mount-point {
  border: 1px solid #9e9e9e;
}
#stripe-element-errors {
  color: #f44336;
  margin-top: 0.5rem;
}
</style>
