import axios from 'axios';
import { getAccessToken } from '@/aws/authentication';
import { empty } from '@/utils';
export async function apiClient(options = {}, baseURL = process.env.VUE_APP_API_END_POINT) {
  const accessToken = await getAccessToken();
  if (!empty(accessToken)) {
    options = {
      ...options,
      Authorization: 'Bearer ' + accessToken,
    };
  }
  const config = {
    baseURL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...options,
    },
  };

  return axios.create(config);
}

export async function handleResp(action) {
  const dataRet = await action;
  return dataRet?.data;
}
