import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';

import '@/assets/styles/index.scss';
import vuetify from './plugins/vuetify';

import apolloProvider from '@/aws/appsync';
import Amplify, * as AmplifyModules from 'aws-amplify';
// import Amplify from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import awsmobile from '@/aws/aws-exports';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

Amplify.configure(awsmobile);

AmplifyModules.Auth.configure(awsmobile);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueToast);
Vue.use(VueGtag, {
  config: {
    id: 'G-WW5252832X',
  },
});

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
