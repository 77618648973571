<template>
  <section class="spinner-container concluded d-flex flex-column" :style="sectionStyle">
    <div v-if="!empty(concludedConfig.text)" class="pa-4 primary--text rounded-xl white font-weight-bold text-h6 max-w-screen-md mb-4">
      <div class="logo-container mb-2" v-if="!empty(concludedConfig.bgForegroundImage)">
        <img class="max-h-10 ma-auto" :src="concludedConfig.bgForegroundImage" alt="isi background" />
      </div>
      <p class="mb-0 concludedMessage text-center" v-html="concludedConfig.text" />
    </div>
    <div v-if="type === 'PRE' && preVideos.length > 0" ref="gallery" class="w-full">
      <div class="swiper-wrapper items-center">
        <div v-for="(vidUrl, index) in preVideos" :key="index" class="swiper-slide">
          <video @load="onIframeLoaded" controls width="100%" :height="`${defaultHeight}px`" v-if="isIsiVideoUrl(vidUrl)" data-source="slide-video" style="background: #000">
            <source :src="vidUrl" type="video/mp4" />
          </video>

          <iframe
            v-else
            width="100%"
            :height="`${defaultHeight}px`"
            :src="vidUrl"
            frameborder="0"
            allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            align-self
            data-source="slide-video"
            style="background: #000"
            @load="() => onIframeLoaded(index)"
          ></iframe>
        </div>
      </div>

      <div class="d-flex items-center justify-center mt-4">
        <div class="slider-nav mr-2">
          <v-btn style="color: #fff" :color="colorFromEvent" class="prev" elevation="4" rounded block>Previous</v-btn>
        </div>
        <div class="slider-nav">
          <v-btn style="color: #fff" :color="colorFromEvent" class="next" elevation="4" rounded block>Next</v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { empty } from '@/utils';
import { get, map, includes, filter } from 'lodash';
import { logout } from '@/aws/authentication';
import 'swiper/swiper-bundle.css';

import swiper, { Navigation } from 'swiper';

const Swiper = swiper;
Swiper.use([Navigation]);
export default {
  name: 'Concluded',
  props: ['event', 'type'],
  data() {
    return {
      defaultHeight: 500,
      slider: null,
      iframeNumber: 0,
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        touchRatio: 0,
        // loop: true,
        navigation: {
          prevEl: '.prev',
          nextEl: '.next',
        },
      },
    };
  },
  computed: {
    colorFromEvent() {
      const { primaryColour, secondaryColor } = this.event;
      return primaryColour == '#ffffff' ? secondaryColor : primaryColour;
    },
    iframeLoad() {
      return filter(get(this.event, 'preVideos'), item => this.isVimeoUrl(item) || this.isYoutubeVideoUrl(item)).length;
    },
    preVideos() {
      return map(get(this.event, 'preVideos'), (item, index) => {
        const url = new URL(item);
        if (this.isVimeoUrl(item)) {
          url.searchParams.set('api', 1);
          // url.searchParams.set('autoplay', 1);
        } else if (this.isYoutubeVideoUrl(item)) {
          url.searchParams.set('controls', 0);
          url.searchParams.set('enablejsapi', 1);
          url.searchParams.set('version', 3);
          url.searchParams.set('playerapiid', 'ytplayer');
          // url.searchParams.set('autoplay', 1);
        }
        url.searchParams.set('index', index);

        return url.toString();
      });
    },
    concludedConfig() {
      const decodedFunc = decodeURIComponent || decodeURI;
      switch (this.type) {
        case 'PRE':
          this.$nextTick(() => {
            this.slider = new Swiper(this.$refs.gallery, {
              ...this.swiperOptions,
              on: {
                init: () => {
                  this.onStopAllVideo();
                },
                slideChange: this.onStopAllVideo,
                slideChangeTransitionEnd: data => this.onPlayNextVideo(data),
              },
            });
            this.$once('hook:destroyed', function () {
              if (this.slider) this.slider.destroy();
            });
          });

          return {
            bgImage: get(this.event, 'preEventConfig.bgImage'),
            bgColor: get(this.event, 'preEventConfig.bgColor'),
            bgForegroundImage: get(this.event, 'preEventConfig.bgForegroundImage'),
            text: decodedFunc(
              get(
                this.event,
                'preEventConfig.text',
                // 'Thank you for being part of our event.<p>We hope you enjoyed it.',
              ),
            ),
          };
        case 'CLOSED':
          logout();
          this.$store.dispatch('resetStore', this.event.id);
          return {
            bgImage: get(this.event, 'postEventConfig.bgImage'),
            bgColor: get(this.event, 'postEventConfig.bgColor'),
            bgForegroundImage: get(this.event, 'postEventConfig.bgForegroundImage'),
            text: decodedFunc(
              get(
                this.event,
                'postEventConfig.text',
                // 'Thank you for being part of our event.<p>We hope you enjoyed it.',
              ),
            ),
          };
        default:
          return {};
      }
    },
    sectionStyle() {
      let dataRet = {};
      const { bgImage, bgColor } = this.concludedConfig;
      if (!empty(bgImage)) {
        dataRet = {
          background: `url(${bgImage}) 50% 50% / cover no-repeat`,
        };
      } else {
        dataRet = {
          background: bgColor,
        };
      }
      return dataRet;
    },
  },
  watch: {
    iframeNumber(value) {
      if (value === this.iframeLoad) {
        this.onStopAllVideo();
        this.onPlayNextVideo(this.slider);
      }
    },
  },
  mounted() {
    const screenHeight = window.innerHeight * 0.7;
    if (this.defaultHeight > screenHeight) {
      this.defaultHeight = screenHeight;
    }
  },
  methods: {
    empty,
    onStopAllVideo() {
      let dataPost = {};
      document.querySelectorAll('iframe[data-source="slide-video"]').forEach(item => {
        const curVideo = item.src;
        if (this.isYoutubeVideoUrl(curVideo)) {
          dataPost = {
            event: 'command',
            func: 'pauseVideo',
            args: '',
          };
        } else if (this.isVimeoUrl(curVideo)) {
          dataPost = {
            method: 'pause',
          };
        }
        item.contentWindow.postMessage(JSON.stringify(dataPost), '*');
      });
      document.querySelectorAll('video[data-source="slide-video"]').forEach(item => {
        item.pause();
        item.currentTime = 0;
      });
    },
    onPlayNextVideo(data) {
      let dataPost = {};
      const videoControl = data.slides[data.activeIndex].querySelector('video');
      if (videoControl) {
        videoControl.play();
      } else {
        const activeVideo = data.slides[data.activeIndex].querySelector('iframe');
        if (this.isYoutubeVideoUrl(activeVideo.src)) {
          dataPost = {
            event: 'command',
            func: 'playVideo',
            args: '',
          };
        } else if (this.isVimeoUrl(activeVideo.src)) {
          dataPost = {
            method: 'play',
          };
        }
        activeVideo.contentWindow.postMessage(JSON.stringify(dataPost), '*');
      }
    },
    isVimeoUrl(url) {
      return includes(url, 'vimeo.com');
    },
    isIsiVideoUrl(url) {
      return includes(url, 'video.isilive.ca');
    },
    isYoutubeVideoUrl(url) {
      return /^.*(youtu\.be\/|v\/|u\/\w\/|embed|&v=)([^#&?]*).*/g.test(url);
    },
    onIframeLoaded() {
      this.iframeNumber++;
    },
  },
};
</script>
