import { Auth } from 'aws-amplify';

export const signUp = async ({ email, password, firstName, lastName, eventId }) => {
  const params = {
    username: email,
    password,
    attributes: {
      name: `${firstName} ${lastName}`,
      family_name: lastName,
      given_name: firstName,
      zoneinfo: eventId,
    },
  };
  return await Auth.signUp(params);
};

export const requestResetPassword = async (email, eventId) => {
  return await Auth.forgotPassword(email, {
    zoneinfo: eventId,
  });
};

export const verifyResetPassword = async (email, password, code) => {
  return await Auth.forgotPasswordSubmit(email, code, password);
};

export const reSendConfirmSignUp = async (email, eventId) => {
  return await Auth.resendSignUp(email, {
    zoneinfo: eventId,
  });
};

export const confirmSignUp = async (email, code) => {
  return await Auth.confirmSignUp(email, code);
};

export const signIn = async (email, password) => {
  return await Auth.signIn(email, password);
};

export const logout = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error('error signing out: ', error);
  }
};

export const getAccessToken = async () => {
  try {
    const respAccess = await Auth.currentSession();
    return respAccess.accessToken.jwtToken;
  } catch (error) {
    console.log({ error });
  }
  return null;
};

export const getCurrentUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log({ error });
  }
  return null;
};
