import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { empty } from '@/utils';
import { getAccessToken, logout } from '@/aws/authentication';

Vue.use(VueRouter);

const routes = [
  {
    path: '/event/:event_id',
    name: 'Event',
    component: () => import('@/views/Event'),
  },
  {
    path: '/:event_id/active-account',
    name: 'ActiveAccount',
    component: () => import('@/views/VerifySignUp'),
  },
  {
    path: '/:event_id/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/VerifySignUp'),
  },
  {
    path: '/:event_id',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/policy/privacy',
    name: 'Privacy',
    component: () => import('@/views/Policy/Privacy'),
  },
  {
    path: '/policy/term-of-use',
    name: 'TermOfUse',
    component: () => import('@/views/Policy/TermsOfUse'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  const { event_id } = to.params;
  let isInvalidSession = empty(store.state.event) || (store.state.event.isPaidEvent ? empty(await getAccessToken()) : empty(store.state.userInfo[event_id]));

  if (isInvalidSession) {
    await logout();
    await store.dispatch('resetStore', event_id);
  }

  if (to.name === 'Privacy' || to.name === 'TermOfUse') {
    return next();
  }
  if (empty(store.state.event)) {
    if (to.name !== 'Home') {
      return next({
        name: 'Home',
        params: to.params,
      });
    }
    return next();
  }

  if (to.name === 'ActiveAccount' || to.name === 'ForgotPassword') {
    return next();
  } else if (isInvalidSession) {
    if (to.name !== 'Home') {
      return next({
        name: 'Home',
        params: to.params,
      });
    }
  } else if (to.name === 'Home') {
    return next({
      name: 'Event',
      params: to.params,
    });
  }
  next();
});

export default router;
